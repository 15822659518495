<template>
  <Layout :title="'回访统计'" @click-left="goBack">
    <div class="title_wrap">
      <div class="new_title">
        <div class="avatar_wrap">
          <img :src="data.avatar" alt="" />
          <i>
            <img src="@/assets/images/douyin-logo.png" alt="" />
          </i>
        </div>
        <div class="title_content">
          <h3>{{ data.nickname }}</h3>
          <div>
            {{ data.brand || "暂无品牌" }} <b>{{ data.code || "--" }}</b>
          </div>
        </div>
      </div>
    </div>

    <div>
      <el-table :data="dataSource">
        <el-table-column
          fixed
          prop="batch"
          label="回访批次"
          width="100"
        ></el-table-column>
        <el-table-column prop="return_visit" label="回访数"></el-table-column>
        <el-table-column
          prop="response_rate_7_total"
          label="7日回复率"
        ></el-table-column>
        <el-table-column
          prop="retention_rate_7"
          label="7日留资率"
        ></el-table-column>
        <el-table-column
          prop="response_rate_1"
          label="1日回复"
        ></el-table-column>
        <el-table-column
          prop="response_rate_2"
          label="2日回复"
        ></el-table-column>
        <el-table-column
          prop="response_rate_3"
          label="3日回复"
        ></el-table-column>
        <el-table-column
          prop="response_rate_4"
          label="4日回复"
        ></el-table-column>
        <el-table-column
          prop="response_rate_5"
          label="5日回复"
        ></el-table-column>
        <el-table-column
          prop="response_rate_6"
          label="6日回复"
        ></el-table-column>
        <el-table-column
          prop="response_rate_7"
          label="7日回复"
        ></el-table-column>
      </el-table>
    </div>
  </Layout>
</template>

<script>
import commonApi from "@/api/common.js";
import Layout from "../layout";
import moment from "moment";
window.moment = moment;
export default {
  components: { Layout },
  data() {
    return {
      data: {},
      columns: [
        //   { title: 'Full Name', width: 100, dataIndex: 'name', key: 'name', fixed: 'left' },
        {
          title: "回访批次",
          dataIndex: "batch",
          width: 100,
          fixed: "left",
        },
        {
          title: "回访数",
          dataIndex: "return_visit",
        },
        {
          title: "7日回复率",
          dataIndex: "response_rate_7_total",
        },
        {
          title: "7日留资率",
          dataIndex: "retention_rate_7",
        },
        {
          title: "1日回复",
          dataIndex: "response_rate_1",
        },
        {
          title: "2日回复",
          dataIndex: "response_rate_2",
        },
        {
          title: "3日回复",
          dataIndex: "response_rate_3",
        },
        {
          title: "4日回复",
          dataIndex: "response_rate_4",
        },
        {
          title: "5日回复",
          dataIndex: "response_rate_5",
        },
        {
          title: "6日回复",
          dataIndex: "response_rate_6",
        },
        {
          title: "7日回复",
          dataIndex: "response_rate_7",
        },
      ],
      dataSource: [
        // {
        //   batch: "2021-03-18",
        //   return_visit: 1230,
        //   retention_rate_7: "12%",
        //   response_rate_7_total: "15%",
        //   response_rate_7: "3%",
        //   response_rate_6: "3%",
        //   response_rate_5: "3%",
        //   response_rate_4: "3%",
        //   response_rate_3: "3%",
        //   response_rate_2: "3%",
        //   response_rate_1: "3%",
        // },
      ],
    };
  },
  created() {
    let user_id = this.$route.query.userId;
    let authorId = this.$route.query.authorId;
    function addRate(number) {
      let n = +number;
      if (isNaN(n)) {
        return "0%";
      } else {
        n = n.toFixed(2);
        return `${n}%`;
      }
    }
    function formatDate(dateStr) {
      let d = moment(dateStr);
      if (d._isValid) {
        return d.format("YYYY-MM-DD");
      } else {
        return "-";
      }
    }
    this.$toast.loading({
      message: "数据加载中...",
      duration: 0,
    });
    commonApi
      .getPlatformInfo(user_id)
      .then(({ data }) => {
        let arr = [];
        if (this.$route.query.platform === "douyin") {
          arr = data.douyinlist;
        } else {
          arr = data.kuaishoulist;
        }
        let selectArr = arr.filter((item) => item.author_id == authorId);
        console.log(selectArr)
        if (selectArr.length === 0) {
          this.$toast.fail("获取数据失败");
        } else {
          this.data = selectArr[0];
        }
      })
      .finally(() => {
        this.$toast.clear();
      });
    commonApi
      .getStatistics({
        author_id: authorId,
      })
      .then((res) => {
        let arr = (res.data.data || []).map((item) => {
          return {
            batch: formatDate(item.visitBatch),
            return_visit: item.fansCount || 0,
            retention_rate_7: addRate(item.replyNDay),
            response_rate_7_total: addRate(item.contactNDay),
            response_rate_7: addRate(item.reply7Day),
            response_rate_6: addRate(item.reply6Day),
            response_rate_5: addRate(item.reply5Day),
            response_rate_4: addRate(item.reply4Day),
            response_rate_3: addRate(item.reply3Day),
            response_rate_2: addRate(item.reply2Day),
            response_rate_1: addRate(item.reply1Day),
          };
        });
        this.dataSource = arr;
      });
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.title_wrap {
  padding: 20px 25px;
}
.new_title {
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
  > .avatar_wrap {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 30px;
    > i {
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      width: 20px;
      height: 20px;
    }
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  > .title_content {
    > h3 {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 10px;
    }
    > div {
      font-size: 10px;
      color: #4a4a4a;
      b {
        color: #5579e2;
      }
    }
  }
}
</style>